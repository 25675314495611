import React from 'react';

const Download = () => {
  return (
    <section className="download section-padding style-5" data-scroll-index="6">
      <div className="container">
       
        <div className="section-head text-center style-4">
          <h2 className="mb-10">{ 'Ready To' } <span>{  'Download' }</span> </h2>
          <p>{  'Your can do more on our mobile applications. Download from App Store or Google Play.' }</p>
          <div className="d-flex align-items-center justify-content-center mt-50">
            <a href="https://www.apple.com/app-store" rel="noreferrer" className="btn rounded-pill bg-blue4 fw-bold text-white me-4" target="_blank">
              <small> <i className="fab fa-apple me-2 pe-2 border-end"></i> { 'Download On App Store' }</small>
            </a>
            <a href="https://play.google.com/store/apps" rel="noreferrer" className="btn rounded-pill hover-blue4 fw-bold border-blue4" target="_blank">
              <small> <i className="fab fa-google-play me-2 pe-2 border-end"></i> {  'Download On Google Play' }</small>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Download